import TagManager from 'react-gtm-module'
import toast from 'react-hot-toast'
import { loadStripe } from '@stripe/stripe-js'
import { captureEvent } from '@sentry/react'
import posthog from 'posthog-js'

import _ from 'lodash'
import { axiosBackendInstance } from '../axios'
import {
  ANALYTICS_EVENT,
  ANALYTICS_FRONTEND_EVENT,
  COINS_DIVIDER,
  ERROR_MESSAGE,
  ERROR_REGEX,
  ERRORS,
  FLY_COINS_MAX_COUNT,
  INSTALL_VALIDATING_MINUTES,
  LOCAL_STORAGE,
  MAX_ATTEMPTS_AMOUNT,
  MILLISECONDS_IN_MINUTE,
  PIGS_DIVIDER,
  REACT_NATIVE_STATUS,
  TIMER_EXPIRED,
  USER_PLATFORM,
  VERIFICATION_STATUS,
} from '../constants'
import { generateAuthLinkPost } from '../services/authService'
import MovingImage from '../components/movingImage'
import { sendEventAnalytic } from '../services/userService'
import { createVerificationSession } from '../services/stripeService'
import { useRefreshToken } from '../hooks/useRefreshToken'
import { errorHandler } from './errorHandler'

export const formatNumber = (number) => {
  return parseFloat(number).toLocaleString('en-US')
}

export const isUserAdult = (birthdate) => {
  const today = new Date()
  const birthDate = new Date(birthdate)

  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDifference = today.getMonth() - birthDate.getMonth()

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }

  return age >= 18
}

export const isUserVerified = (user) => {
  return user?.identityVerification?.status === VERIFICATION_STATUS.VERIFIED
}

export const isUserOutOfAttempts = (user) => {
  return user?.identityVerification?.attempts >= MAX_ATTEMPTS_AMOUNT
}

export const getUserPlatform = (initialPlatform = USER_PLATFORM.ANDROID) => {
  let platform = initialPlatform

  if (/android/i.test(navigator.userAgent)) {
    platform = USER_PLATFORM.ANDROID
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    platform = USER_PLATFORM.IOS
  }

  return platform
}

export const isUserFromMobileDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.ReactNativeWebView
}

export const dateWithoutTimezone = (date) => {
  const dateObject = new Date(date)
  const userTimezoneOffset = dateObject.getTimezoneOffset() * 60000

  return new Date(dateObject.getTime() - userTimezoneOffset)
}

export const formatDate = (date, format) => {
  date = date._seconds ? new Date(date._seconds * 1000) : new Date(date)

  return date.toLocaleDateString(
    ...(format || [
      'en-US',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
    ])
  )
}

export const isLinkActive = (route) => location.pathname.startsWith(route)

export const generateId = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const debounce = (fn, delay) => {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => fn(...args), delay)
  }
}

export const handleError = (err, customMessage = '') => {
  toast.error(customMessage || ERROR_MESSAGE)
  console.error(err)
}

export const getRegistrationTitle = (user) => {
  const firstName = sessionStorage.getItem('firstName') || user?.firstName || ''

  return `Welcome${firstName && ', '}${firstName}!`
}

export const handleConnectSecondGmail = (userId, link = 'auth/gmail', signupAttrParam) => {
  generateAuthLinkPost(link, ['email'], signupAttrParam, userId)
    .then((authLink) => {
      if (link === 'auth/gmail') {
        localStorage.removeItem('jwtToken')
      }
      window.location.href = authLink
    })
    .catch(handleError)
}

export const selectReferralRewardTitle = (reward) => {
  const titles = {
    SIGN_UP_REFERRER: `${reward.connectedUserName} created an account`,
    SIGN_UP_REFERRED: `You were referred by ${reward.connectedUserName}`,
    CLAIM_REWARD_REFERRER: `${reward.connectedUserName} made a purchase`,
    CLAIM_REWARD_REFERRED: `Bonus referral reward with purchase`,
  }
  return titles[reward.reason]
}

export const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...'
  }
  return str
}

export const objectEncode = (object) => {
  const str = []
  Object.keys(object).forEach((index) => {
    str.push(`${encodeURIComponent(index)}=${encodeURIComponent(object[index])}`)
  })
  return str.join('&')
}

export const getPlatform = () => {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    iosPlatforms = ['iPhone', 'iPad', 'iPod']

  if (iosPlatforms.indexOf(platform) !== -1) {
    return USER_PLATFORM.IOS
  }

  if (/Android/.test(userAgent)) {
    return USER_PLATFORM.ANDROID
  }

  return 'unknown'
}

export const initializeTagManager = (userId = null) => {
  try {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GOOGLE_TAG_ID,
      ...(userId && {
        dataLayer: {
          ...ANALYTICS_FRONTEND_EVENT.SET_USER_ID,
          user_id: userId,
        },
      }),
    })
  } catch (err) {
    captureEvent({
      message: ERRORS.GTM_INIT,
      level: 'error',
      extra: { err: err.message },
    })
  }
}

export const sendGA4Event = (eventData, params = {}, token) => {
  if (!eventData.eventParams) {
    eventData.eventParams = {}
  }

  Object.assign(eventData.eventParams, params)

  sendEventAnalytic(eventData, token)
}

export const sendRNMessage = (message) => {
  try {
    window.ReactNativeWebView?.postMessage(JSON.stringify(message))
  } catch (err) {
    captureEvent({
      message: ERRORS.REACT_NATIVE_POST_MESSAGE,
      level: 'error',
      extra: { err: err.message, data: message },
    })
  }
}

export const isOnNativeAndroidApp = () => {
  return !!window.ReactNativeWebView
}

export const handleRNLogin = async (geoData) => {
  sendRNMessage({
    status: REACT_NATIVE_STATUS.LOGIN,
    geoData,
    referralId: sessionStorage.getItem(LOCAL_STORAGE.REFERRAL_ID) || null,
  })
}

export const createImagesArray = (count, set, src, headerDiv, div, duration = 1) => {
  const array = []

  for (let i = 0; i < Math.min(count, FLY_COINS_MAX_COUNT); i++) {
    array.push(
      <MovingImage
        key={i + src}
        div={div}
        src={src}
        index={i}
        headerDiv={headerDiv}
        animationDuration={duration}
      />
    )
  }

  set(array)
}

export const createVirtualCurrencyImagesArrays = (
  sourceDiv,
  earnedCoins,
  earnedPiggy,
  setCoinsArray,
  setPigsArray,
  duration
) => {
  createImagesArray(
    Math.round(earnedCoins / COINS_DIVIDER),
    setCoinsArray,
    '/images/coin.png',
    getHeaderImageDiv(false),
    sourceDiv,
    duration
  )

  createImagesArray(
    Math.round(earnedPiggy / PIGS_DIVIDER),
    setPigsArray,
    '/images/pig.png',
    getHeaderImageDiv(true),
    sourceDiv,
    duration
  )
}

export const getHeaderImageDiv = (isPig) =>
  document.getElementsByClassName(`gamesHeaderInput ${isPig ? 'pig' : 'coin'}`)[0]

export const sumFloat = (a, b) => Number(((a * 100 + b * 100) / 100).toFixed(2))

export const isRouteAvailable = (routes, pathname = location.pathname) => {
  const pathItems = pathname.split('/')

  return routes.some((route) => {
    const routeItems = route.path.split('/')

    if (routeItems.length === pathItems.length) {
      let exists = true

      routeItems.map((text, key) => {
        exists = exists && (text.startsWith(':') || text === pathItems[key])
      })

      return exists
    }

    return false
  })
}

export const checkDateIsLessToday = (date) => date._seconds <= new Date().getTime() / 1000

export const checkVisible = (elm) => {
  const rect = elm.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom - 60 < 0 || rect.top - viewHeight + 70 >= 0)
}

export const handleStripeIdentity = (userId, navigate) => {
  createVerificationSession()
    .then(async (session) => {
      const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_API_KEY}`)
      stripe.verifyIdentity(session.clientSecret).then((data) => {
        if (!data.error && data.error?.code !== 'session_cancelled') {
          navigate('/verification/result')
        }

        sendGA4Event(ANALYTICS_EVENT.IDENTITY_VERIFICATION_FINISH, {
          session_id: session.sessionId,
        })
      })
    })
    .catch(handleError)
}

export const openMMPLink = (mmpLink, user, gameDetails) => {
  if (!window.ReactNativeWebView) {
    window.open(mmpLink)
  } else {
    sendRNMessage({
      status: REACT_NATIVE_STATUS.TRY_GAME,
      userId: user.id,
      id: gameDetails.id,
      url: mmpLink,
      unifiedAppId: gameDetails.unified_app_id,
    })
  }
}

/**
 * Handler for axios errors
 * Depending on the code, message, or parameters of the error, it performs different functions and throws the error that was received
 *
 * @function
 * @returns {void}
 */
// Ideally this refresh would be in a react hook but our structure is non-standard so instead I store a refrence to the function to prevent multiple invalid requests from all refreshing
let refreshingFunc = undefined
export const errorInterceptorHandler = async (error) => {
  const code = _.get(error, 'response.data.code')
  const message = _.get(error, 'response.data.message')
  const additionalParams = _.get(error, 'response.data.additionalParams')
  const refresh = useRefreshToken()

  const prevRequest = error?.config

  if (error?.response?.status === 403 && !prevRequest.sent) {
    try {
      prevRequest.sent = true
      if (!refreshingFunc) {
        refreshingFunc = refresh()
      }
      const newAccessToken = await refreshingFunc
      prevRequest.headers.Authorization = `Bearer ${newAccessToken}`
      localStorage.setItem(LOCAL_STORAGE.JWT_TOKEN, newAccessToken)
      return axiosBackendInstance(prevRequest).catch((err) => {
        throw err
      })
    } catch (err) {
      captureEvent({
        message: ERRORS.LOGOUT_REFRESH,
        level: 'error',
        extra: { err: err.message },
      })

      window.location.href = '/logout'
      return
    } finally {
      refreshingFunc = undefined
    }
  }

  if (ERROR_REGEX.account_blocked.test(message) && window.location.pathname !== '/blocked') {
    window.location.href = '/blocked'
  }

  if (ERROR_REGEX.user_not_found.test(message)) {
    localStorage.removeItem(LOCAL_STORAGE.JWT_TOKEN)
    sendRNMessage({ status: REACT_NATIVE_STATUS.LOGOUT_USER })
    posthog.reset(true)
    window.location.href = '/auth'
  }

  if (code && errorHandler[code]) {
    await errorHandler[code](message, additionalParams)
  } else if (error.message.search('throttled') !== -1 && !window.ReactNativeWebView) {
    window.location.reload()
  }
  throw error
}

export const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length == 11 && match[7]
}

export const fetchToBase64 = async (src, onSuccess, errorMessage, onError = null) => {
  return await fetch(src)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const dataUrl = reader.result.toString()

        onSuccess(dataUrl)
      }
    })
    .catch((err) => {
      captureEvent({
        message: errorMessage,
        level: 'error',
        extra: { err: err.message },
      })

      onError && onError()
    })
}

/**
 * Play on day visibility check
 *
 * @param {object} day - day data
 * @param {string} timer - expiration timer string
 * @returns {boolean}
 */
export const checkDayVisible = (day, timer) => {
  return (
    !day.claimed &&
    (timer !== TIMER_EXPIRED || (timer === TIMER_EXPIRED && 'claimed' in day)) &&
    (!day.endDate || day.endDate?._seconds > new Date().getTime() / 1000 || 'claimed' in day)
  )
}

/**
 * Playtime visibility check
 *
 * @param {object} offer
 * @param {object} playtime
 * @param {number} index
 * @returns {boolean}
 */
export const checkPlaytimeVisible = (offer, playtime, index) => {
  if (
    offer.expirationTimestamp &&
    new Date().getTime() / 1000 >= offer.expirationTimestamp._seconds
  ) {
    return playtime.completed && !playtime.claimed
  } else {
    if (
      offer.maxPlaytimeTasksAvailable &&
      offer.maxPlaytimeTasksAvailable !== -1 &&
      index >= offer.maxPlaytimeTasksAvailable
    ) {
      return false
    }

    return !playtime.claimed
  }
}

/**
 * Returns start date of the play on day task
 *
 * @param {object} day - play on day object
 * @param {boolean} isActivated - check if offer is activated
 * @param {boolean} validating - check if offer is validating
 * @returns {string | null}
 */
export const getDayExpirationTimestamp = (day, isActivated = true, validating = false) => {
  if (
    isActivated &&
    !validating &&
    day.startDate?._seconds >= new Date().getTime() / 1000 &&
    day.startDate?._seconds - new Date().getTime() / 1000 < 86400
  ) {
    return day.startDate
  }

  return null
}

/**
 * Function for checking whether the offer is validating
 *
 * @param {object} activatedOffer
 * @param {object} specialOffer
 * @returns {boolean}
 */
export const checkOfferValidating = (activatedOffer, specialOffer) => {
  //Real install attribution logic
  if (activatedOffer?.mmpAttributionRequiredForRewards) {
    return !activatedOffer.isInstallAttributed
  }

  //Fake validation is not needed for playtime-rewarded offers or non-UA offers
  if (specialOffer.playtimeRewards || !specialOffer.isUAOffer) {
    return false
  }

  //Fake validation logic (timer)
  const fakeValidationTime =
    (+process.env.REACT_APP_OFFER_INSTALL_VALIDATING_TIME ||
      INSTALL_VALIDATING_MINUTES * MILLISECONDS_IN_MINUTE) / 1000

  return (
    activatedOffer.activationTimeStamp._seconds + fakeValidationTime > new Date().getTime() / 1000
  )
}

/**
 * Reorders the gift card denominations array.
 *
 * @param {Object[]} denominations - An array of gift card denominations.
 * @returns {Object[]} A new array with re-ordered gift card denominations.
 */
export const reorderGiftCards = (denominations = []) => {
  // Don't re-order elements if less than 4 denominations available
  if (denominations.length <= 3) {
    return denominations
  }

  // Create a copy of the original array
  const newOrder = [...denominations]

  // Remove the last two elements from the copy
  const lastTwo = newOrder.splice(-2)

  // Insert them at indices 1 and 2 in the copy
  newOrder.splice(1, 0, ...lastTwo)

  return newOrder
}
